<template>
  <b-card :title="$t('members.about')" class="mb-2 mb-0">
    <b-spinner
      v-if="isLoading"
      type="grow"
      small
      class="text-primary d-block m-auto"
    />
    <div v-else-if="hasAbout">
      <content-with-anchors 
        tag="p" 
        :content="member.summary" 
        class="mb-2" 
      />
      <!-- Member Type -->
      <b-row v-if="member.type">
        <b-col class="font-weight-bold">
          {{ $t("user-type") }}
        </b-col>
        <b-col>
          <p>
            {{ getTypeName(member) }}
          </p>
        </b-col>
      </b-row>

      <!-- Contact information -->
      <div v-if="showContactInfo">
        <!-- Emails -->
        <b-row class="mb-1">
          <b-col class="font-weight-bold">
            {{ $t("backoffice.settings.subtitle-emails") }}:
          </b-col>
          <b-col>
            <!-- Main email -->
            <p v-if="showMainEmail">
              <a target="_blank" :href="`mailto:${member.email}`">
                {{ member.email }}
              </a>
            </p>
            <!-- Emails -->
            <div v-if="hasEmails">
              <div v-for="email in member.emails" :key="email.key">
                <small class="text-muted mb-0">{{ email.label }}</small>
                <p>
                  <a target="_blank" :href="`mailto:${email.email}`">
                  {{ email.email }}
                  </a>
                </p>
              </div>
            </div>
            <div v-else>
              -
            </div>
          </b-col>
        </b-row>

        <!-- Phones -->
        <b-row class="mb-1">
          <b-col class="font-weight-bold">
            {{ $t("backoffice.settings.subtitle-phones") }}:
          </b-col>
          <b-col>
            <div v-if="hasPhones">
              <div v-for="(phone, index) in member.phones" :key="index">
                <small class="text-muted mb-0">{{ phone.label }}</small>
                <p v-if="phone.country && phone.country.phoneCode" :class="member.phones.length > 1 ? 'mb-25' : ''">
                  <a :href="`tel:+${phone.country.phoneCode}${getPhoneNumber(phone)}`">
                    +{{ phone.country.phoneCode }} {{ getPhoneNumber(phone) }}
                  </a>
                </p>
                <p v-else :class="member.phones.length > 1 ? 'mb-25' : ''">
                  <a :href="`tel:${getPhoneNumber(phone)}`">
                    {{ getPhoneNumber(phone) }}
                  </a>
                </p>
              </div>
            </div>
            <div v-else>
              -
            </div>
          </b-col>
        </b-row>

        <!-- Locations -->
        <b-row class="mb-1">
          <b-col class="font-weight-bold">
            {{ $t("backoffice.settings.subtitle-locations") }}:
          </b-col>
          <b-col>
            <div v-if="hasLocations">
              <p v-for="location in member.locations" :key="location.key">
                {{ location.address }}
              </p>
            </div>
            <div v-else>
              -
            </div>
          </b-col>
        </b-row>
      </div>

      <!--div v-else>
        <div
          class="d-flex flex-column justify-content-center align-items-center mb-1"
        >
          <img class="placeholder" :src="aboutPlaceholder" />
          <p class="text-primary font-weight-bold">
            {{ $t("members.no-about-message") }}
          </p>
        </div>
      </div-->

      <!-- Custom fields -->
      <div v-for="(field, index) in member.custom" :key="index">
        <b-row
          v-if="
            field.value || (typeof field.value !== 'string' && !field.value)
          "
          class="mb-1"
        >
          <b-col class="font-weight-bold"> {{ getName(field) }}: </b-col>
          <b-col>
            {{ getValue(field.value) }}
          </b-col>
        </b-row>
      </div>

      <!-- Classifiers -->
      <template v-if="classifiers && classifiers.length > 0">
        <div 
          v-for="(classifier, name) in sortedClassifiers" 
          :key="classifier.key"
        >
          <div class="w-100">
            <span class="font-weight-bold">
              {{ name }}:
            </span>
          </div>
          <template v-if="isLatamfm">
            <p
              v-for="classif in classifier"
              :key="classif.key"
            >
              {{
                translateTranslationTable(
                  $store.state.locale.currentLocale,
                  classif.name
                )
              }}
            </p>
          </template>
          <template v-else>
            <span v-for="classif in classifier" :key="classif.key">
              <b-badge class="mb-50 mr-50" :style="classifierStyle(classif)">
                {{
                  translateTranslationTable(
                    $store.state.locale.currentLocale,
                    classif.name
                  )
                }}
              </b-badge>
            </span>
          </template>
        </div>
      </template>

      <!-- Links -->
      <h4 class="mt-2">
        <hr />
        {{ $t("links.name") }}
      </h4>
      <div v-if="hasLinks" class="d-flex">
        <p v-for="(link, index) in userLinks" :key="index">
          <a :href="toAbsoluteURL(link.url)" target="_blank" class="mr-1">
            <feather-icon
              :icon="
                link.label === 'Website' || link.label === ''
                  ? 'GlobeIcon'
                  : `${link.label}Icon`
              "
              size="18"
              class="social-icons"
            />
          </a>
        </p>
      </div>
      <div v-else>-</div>
    </div>

  </b-card>
</template>
<script>
import ContentWithAnchors from "@core/components/string-utils/ContentWithAnchors.vue";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import AboutPlaceholder from "@/assets/images/placeholders/light/aboutCommunity.svg";
import Service from "@/config/service-identifiers";
import { dateValidator } from "@/@core/utils/validations/validators";
import { toAbsoluteURL } from "@core/utils/url-utils";
import { watch } from "vue-echarts";

export default {
  components: { ContentWithAnchors },
  props: {
    member: {
      type: Object,
      required: true,
    },
    classifiers: {
      type: Array,
      default: () => [],
    },
    hasRelation: Boolean,
  },
  data() {
    return {
      types: [],
      isLoading: false,
    };
  },
  computed: {
    collective() {
      return this.$store.state.collective.collective;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.currentCollective;
    },
    isLatamfm() {
      return this.mainSpace.key === '5e26cee0-0cbc-11ed-89a7-a1d49fe606c5';
    },
    isCOAM() {
      console.log('isCOAM() this.mainSpace.key', this.mainSpace.key);
      const inmomatKey = '8ed3c7a0-038d-11ee-9995-ad98e7433482';
      const isInmomat = this.mainSpace.key === inmomatKey;
      const semanaConstruccionKey = '6846b490-e314-11ef-b56e-6d0988471bcc';
      const isSemanaConstruccion = this.mainSpace.key === semanaConstruccionKey;
      return isInmomat || isSemanaConstruccion;
    },
    isCoeic() {
      return this.mainSpace.key === '8f30c710-a169-11ed-88c5-c1eb2d036009';
    },
    showMainEmail() {
      return this.isCOAM;
    },
    showContactInfo() {
      return this.hasRelation || this.isCoeic || this.isCOAM;
    },
    cFields() {
      return this.$store.getters.customFields;
    },
    aboutPlaceholder() {
      return AboutPlaceholder;
    },
    isLoggedMember() {
      return this.$store.getters.loggedMember.key === this.member.key;
    },
    userLinks() {
      return this.member?.links ? this.member?.links : null;
    },
    hasLinks() {
      return this.userLinks && this.userLinks.length > 0;
    },
    userEmails() {
      return this.member?.emails ? this.member?.emails : null;
    },
    hasEmails() {
      return this.userEmails && this.userEmails.length > 0;
    },
    userPhones() {
      return this.member?.phones ? this.member?.phones : null;
    },
    hasPhones() {
      return this.userPhones && this.userPhones.length > 0;
    },
    userLocations() {
      return this.member?.locations ? this.member?.locations : null;
    },
    hasLocations() {
      return this.userLocations && this.userLocations.length > 0;
    },
    hasContactData() {
      return true;  //this.hasRelation && (this.hasPhones || this.hasEmails || this.hasLocations);
    },
    hasAbout() {
      if(!this.member){
        return false;
      }
      return (
        this.member.summary ||
        this.hasContactData ||
        this.isCOAM ||
        this.hasLinks ||
        this.classifiers?.length > 0 ||
        this.member.custom?.some((item) => item.value !== "") ||
        this.member.type
      );
    },
    sortedClassifiers() {
      const newObject = {};
      console.log('sortedClassifiers() classifiers', this.classifiers);
      
      this.classifiers?.forEach((item) => {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName
            )
          ].push(item);
        }
      });
      return newObject;
    },
  },
  async created() {
    console.log('AboutMember created() with this.classifiers:', this.classifiers);

    // this.member.emails.push({ email: this.member.email });
    await this.fetchCustomFields();
    // Fetch member types
    await this.fetchTypes();
  },
  watch: {
    types(old, nw) {
      this.isLoading = true;
      setInterval(() => {
        this.isLoading = false;
      }, 200);
    },
  },
  methods: {
    async fetchCustomFields() {
      await this.$store.dispatch("fetchCustomFields", { modelType: "member" });
    },
    translateTranslationTable,
    icon(classifier) {
      return classifier.iconClass.split("-").pop();
    },
    classifierStyle(classifier) {
      return {
        color: classifier.fontColor,
        "background-color": classifier.backgroundColor,
      };
    },
    translatedFieldName(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field.name
      );
    },
    async fetchTypes() {
      const response = await this.$store.$service[Service.BackendClient].get(
        "types",
        {
          params: {
            communitySlug: this.currentCollective.slug,
            page: 1,
            count: 1000,
            modelType: "member",
          },
        }
      );
      this.types = response.data.data;
    },
    getTypeName(member) {
      const type = this.types?.find((item) => item?.key === member?.type?.key);
      if (type) {
        return type?.name[this.$store.state.locale.currentLocale] ||
        Object.values(type?.name)[0];
      }
      return '';
    },
    getName(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field.customFieldName
      );
    },
    async handleCustomFields() {
      await this.fetchData();
    },
    getValue(value) {
      if (dateValidator(value)) {
        return value.split("-").reverse().join("-");
      }
      if (value === true) {
        return this.$t("yes");
      }
      if (value === false) {
        return this.$t("no");
      }
      return value;
    },
    getPhoneNumber(phone){
      return phone.phone ?? phone.number;
    },
    toAbsoluteURL,
  },
};
</script>

<style lang="scss" scoped>
  .placeholder {
    height: 160px;
  }
  .social-icons {
    vertical-align: top;
  }
</style>
